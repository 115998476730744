body {
  margin: 0;
  font-family: "Lato";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.device {
  display: inline-block;
  position: relative;
  box-sizing: content-box !important;
}

.device.tablet {
  width: 95%;
  padding: 60px 15px;
  background-color: #eef4f5;
  border-radius: 44px;
}

.device .screen {
  width: 100%;
  position: relative;
  height: 85vh;
  z-index: 20;
  background: white;
  overflow: hidden;
  display: block;
  border-color: #eef4f5;
  border-width: thin;
  border-radius: 0px;
  box-shadow: 0 0 0 2px #eef4f5;
}

.device .landscape_screen {
  width: 100%;
  height: 65vh;
  position: relative;
  z-index: 20;
  background: white;
  overflow: hidden;
  display: block;
  border-color: rgb(224, 224, 224);
  border-radius: 1px;
  box-shadow: 0 0 0 2px rgb(224, 224, 224);
}

.device .tv-screen {
  width: 100%;
  position: relative;
  z-index: 3;
  overflow: hidden;
  display: block;
  border-radius: 1px;
  box-shadow: 0 0 0 2px #595959;
}

.device.tablet:before {
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  position: absolute;
  content: "";
  display: block;
  top: 4px;
  left: 4px;
  border-radius: 40px;
  background: white;
}
.device.tablet .power {
  background: white;
  position: absolute;
  top: 0.5em;
  left: 40%;
  color: #111111;
  font-size: 20px;
}

.device.tablet .home {
  background: white;
  position: absolute;
  left: 42%;
  bottom: 0.5em;
  color: #111111;
  font-size: 20px;
  border: 0px #fcfcfc;
}
/*.device.tablet .home:after {
  width: 15px;
  height: 15px;
  margin-top: -8px;
  margin-left: -8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  position: absolute;
  display: block;
  content: '';
  top: 50%;
  left: 50%;
}*/
.device.tablet.landscape {
  height: 90%;
  width: 90%;
  padding: 15px 65px;
}
.device.tablet.landscape .power {
  left: calc(100% - 60px);
  top: 50%;
  margin-left: 5px;
  margin-top: -25px;
}
.device.tablet.landscape .home {
  top: 50%;
  left: 22px;
  margin-left: -5px;
  margin-top: -25px;
}
.device.tablet.grey {
  backgroundcolor: "rgb(224, 224, 224)";
}
.device.tablet.grey:before {
  background: #fcfcfc;
}
.device.tablet.grey .home {
  background: #fcfcfc;
  box-shadow: inset 0 0 0 1px #bcbcbc;
}
.device.tablet.grey .home:after {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.device.tv {
  padding: 1em;
  width: 92%;
  height: 92%;
  box-shadow: 0 0 0 2px #595959;
  border-radius: 1px;
  background-color: #fcfcfc;
}

.device.tv .logo {
  position: relative;
  height: 6px;
  margin-left: 40%;
  background: white;
  color: #595959;
  font-size: 12px;
  padding-top: 2px;
  font-weight: bold;
}

.device.phone {
  width: 100%;
  height: 96%;
  background: #595959;
  border-radius: 34px;
  padding: 40px 8px;
}
.device .phone-screen {
  width: 100%;
  position: relative;
  height: 100%;
  z-index: 3;
  background: white;
  overflow: hidden;
  display: block;
  border-radius: 1px;
  box-shadow: 0 0 0 2px #595959;
  border: 1px #595959;
}
.device.phone .overflow {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 34px;
  overflow: hidden;
}

.device.phone .speaker {
  height: 22px;
  width: 100%;
  left: 20%;
  position: absolute;
  top: 8px;
  z-index: 1;
  border-radius: 8px;
}
.device.phone .camera {
  height: 18px;
  width: 18px;
  left: 86px;
  position: absolute;
  top: 18px;
  background: #212b36;
  z-index: 1;
  border-radius: 100%;
}
.device.phone .camera:before {
  content: "";
  height: 8px;
  width: 8px;
  left: -22px;
  position: absolute;
  top: 5px;
  background: #212b36;
  z-index: 1;
  border-radius: 100%;
}
.device.phone .inner {
  width: 100%;
  height: calc(100% - 8px);
  position: absolute;
  top: 2px;
  content: "";
  left: 0px;
  border-radius: 34px;
  border-top: 2px solid #595959;
  border-bottom: 2px solid #595959;
  border-left: 1px solid #595959;
  border-right: 1px solid #595959;
  background: white;
  z-index: 1;
  box-shadow: inset 0 0 6px 0 rgba(255, 255, 255, 0.5);
}
.device.phone .shadow {
  box-shadow: inset 0 0 60px 0 white, inset 0 0 30px 0 rgba(255, 255, 255, 0.5),
    0 0 20px 0 white, 0 0 20px 0 rgba(255, 255, 255, 0.5);
  height: 101%;
  position: absolute;
  top: -0.5%;
  content: "";
  width: calc(100% - 20px);
  left: 10px;
  border-radius: 38px;
  z-index: 5;
  pointer-events: none;
}
.device.phone .screen {
  border-radius: 14px;
  box-shadow: none;
}
.device.phone.landscape {
  height: 400px;
  width: 822px;
  padding: 10px 45px;
}
.device.phone.landscape .speaker {
  height: 56px;
  width: 8px;
  top: 50%;
  margin-top: -28px;
  margin-left: 0;
  right: 25px;
  left: auto;
}
.device.phone.landscape .camera {
  top: 86px;
  right: 18px;
  left: auto;
}
.device.phone.landscape .camera:before {
  top: -22px;
  left: 5px;
}
.device.phone.landscape .inner {
  height: 100%;
  width: calc(100% - 8px);
  left: 2px;
  top: 0;
  border-top: 0;
  border-bottom: 0;
  border-left: 2px solid #9fa0a2;
  border-right: 2px solid #9fa0a2;
}
.device.phone.landscape .shadow {
  width: 101%;
  height: calc(100% - 20px);
  left: -0.5%;
  top: 10px;
}
